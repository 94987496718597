import React, { useEffect } from 'react';
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import FaltasTabla from "../components/incidencias/TablaFaltas";
import IncidentForm from "../components/incidencias/incidenciasFecha";
import GuardiasFindeSemana from "../components/incidencias/GuardiasFindeSemana";
import M from 'materialize-css/dist/js/materialize.min.js'; // Import Materialize CSS
import Footer from '../components/Footer';


const IncidenciasPage = () => {
  useEffect(() => {
    // Ensure all components are properly initialized
    M.AutoInit();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container">
        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias por dia
        </StyledSubtitle>
        <div>
          <FaltasTabla />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Crear Incidencia por fecha
        </StyledSubtitle>
        <div>
          <IncidentForm />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias Fines de Semana
        </StyledSubtitle>
        <div>
          <GuardiasFindeSemana />
        </div>

      </div>
      <Footer/>
    </div>
  );
};

export default IncidenciasPage;