import React, { useState, useEffect, useRef } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import { useGetClientId } from "../../hooks/useGetUserClientId";
import { useForm } from 'react-hook-form';
import { StyledFormButton } from "../Styles";

const AltaEmpleado = () => {
  const CLIENTID = useGetClientId();
  const timePickerRefHe = useRef(null);
  const timePickerRefHs = useRef(null);
  const datePickerRefFechaDeInicio = useRef(null);

  const contracts = {
    "1": "5-Corporativo licco",
    "7": "1074-FONACOT",
    "8": "1075-BIENESTAR",
    "9": "1076-INFONATEL VANGUARDIA",
    "11": "1080-BANXICO GVIC",
    "12": "1081-REPUVE GVIC",
    "13": "1082-PRODECON GVIC",
    "14": "1083-NAFIN GVIC",
    "15": "1084-INFONATEL GTI",
    "16": "1085-FULLER GVIC",
    "17": "1086-BACK OFFICE",
  };

  const [formData, setFormData] = useState({
    empid: '',
    nombre: '',
    paterno: '',
    materno: '',
    curp: '',
    puesto: '',
    site: '',
    sucursal: '',
    he: '',
    hs: '',
    fecha_entrada: '',
    email: '',
    tipo: '',
  });

  const [curpList, setCurpList] = useState([]);
  const [empidList, setEmpidList] = useState([]);
  const [errors, setErrors] = useState({
    curp: '',
    curpExists: '',
    empid: '',
    empidExists: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize time picker for Horario Entrada
    M.Timepicker.init(timePickerRefHe.current, {
      twelveHour: false, // Use 24-hour format
      defaultTime: 'now',
      autoClose: true,
      onSelect: (hour, minute) => {
        setFormData(prevData => ({
          ...prevData,
          he: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
        }));
      }
    });

    // Initialize time picker for Horario Salida
    M.Timepicker.init(timePickerRefHs.current, {
      twelveHour: false, // Use 24-hour format
      defaultTime: 'now',
      autoClose: true,
      onSelect: (hour, minute) => {
        setFormData(prevData => ({
          ...prevData,
          hs: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
        }));
      }
    });

    // Initialize date picker for Fecha de Inicio
    M.Datepicker.init(datePickerRefFechaDeInicio.current, {
      format: 'yyyy-mm-dd', // Date format
      autoClose: true,
      onSelect: (date) => {
        const formattedDate = date.toISOString().split('T')[0];
        setFormData(prevData => ({
          ...prevData,
          fechaDeInicio: formattedDate
        }));
      }
    });
  }, []);

  useEffect(() => {
    // Fetch existing CURP and empid values
    fetch(`https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${CLIENTID}`)
      .then(response => response.json())
      .then(data => {
        setCurpList(data.map(emp => emp.curp));
        setEmpidList(data.map(emp => emp.empnum));
      });
  }, [CLIENTID]);

  useEffect(() => {
    // Validate CURP and empid on input change
    const { curp, empid } = formData;

    let newErrors = { curp: '', curpExists: '', empid: '', empidExists: '' };

    if (curpList.includes(curp)) {
      newErrors.curpExists = 'CURP ya registrado';
    } else if (curp.length !== 18 || !/^[a-zA-Z0-9]{18}$/.test(curp)) {
      newErrors.curp = 'No son 18 dígitos';
    }

    if (empidList.includes(empid)) {
      newErrors.empidExists = 'ID de empleado ya registrado';
    }

    setErrors(newErrors);
  }, [formData.curp, formData.empid, curpList, empidList]);

  useEffect(() => {
    // Set sucursal based on CLIENTID
    if (CLIENTID in contracts) {
      setFormData(prevData => ({
        ...prevData,
        sucursal: contracts[CLIENTID]
      }));
    }
  }, [CLIENTID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (errors.curp || errors.curpExists || errors.empid || errors.empidExists) {
      M.toast({ 
        html: 'Por favor corrige los errores antes de guardar.', 
        classes: 'red',
        displayLength: 4000,
        inDuration: 300,
        outDuration: 375 
      });
      return;
    }

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Prepare payload with additional fields
    const payload = {
      empnum: formData.empid,
      nombre: formData.nombre.toString().toUpperCase(),
      paterno: formData.paterno.toUpperCase(),
      materno: formData.materno.toUpperCase() || " ",
      curp: formData.curp.toUpperCase(),
      sucursal: formData.sucursal,
      frecuenciaDePago: "QUINCENAL",
      fechaDeInicio: formData.fecha_entrada || getTodayDate(),
      fotoEnviada: 'Si',
      email: formData.email || " ",
      fijamovil: 'Fija',
      horarioDeEntrada24hh: formData.he,
      horarioDeSalida60mm: formData.hs,
      toleranciaARetardoMmssNo: '2',
      horasExtraSino: 'IT',
      turnoDominicalSino: formData.puesto || " ",
      diasDeDescanso16: formData.site || " ",
      horasSemanales: '48',
      activoInactivo: 'Activo',
      clientid: CLIENTID
    };

    console.log('Form submitted:', payload);

    // Perform the fetch or any other operation to send the data
    fetch('https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleado', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'no-cors',  // Temporarily using no-cors mode
      body: JSON.stringify(payload)
    })
    .then(() => {
      M.toast({ 
        html: 'Usuario guardado exitosamente!', 
        classes: 'green',
        displayLength: 4000,
        inDuration: 300,
        outDuration: 375 
      });
      // Clear form data after successful submission
      setFormData({
        empid: '',
        nombre: '',
        paterno: '',
        materno: '',
        curp: '',
        puesto: '',
        sucursal: '',
        he: '',
        hs: '',
        frecuencia: '',
        fecha_entrada: '',
        email: '',
        tipo: '',
        site: '',
      });
    })
    .catch(error => {
      M.toast({ 
        html: 'Error al guardar el usuario.', 
        classes: 'red',
        displayLength: 4000,
        inDuration: 300,
        outDuration: 375 
      });
      console.error('Error:', error);
    });
  };

  return (
    <div className="container">
      <div className="section">
        <form onSubmit={handleSubmit}>
          <div className="row" style={{ marginBottom: '10px' }}>
            <div className="input-field col s2">
              <input
                id="empid"
                type="text"
                name="empid"
                className="validate"
                value={formData.empid}
                onChange={handleInputChange}
                required
                minLength="4"
                maxLength="6"
                pattern="[0-9]*"
              />
              <span className="helper-text" data-error={errors.empid} data-success="ID válido"></span>
              <span className="helper-text red-text" data-error={errors.empid || errors.empidExists} style={{color: 'red'}}>{errors.empid || errors.empidExists}</span>
              <label htmlFor="empid">No. Empleado</label>
            </div>
            <div className="input-field col s4">
              <input
                id="nombre"
                type="text"
                name="nombre"
                className="validate"
                value={formData.nombre}
                onChange={handleInputChange}
                pattern='[a-zA-Z\s]+'
                required
              />
              <label htmlFor="nombre">Nombre</label>
            </div>
            <div className="input-field col s3">
              <input
                id="paterno"
                type="text"
                name="paterno"
                className="validate"
                value={formData.paterno}
                onChange={handleInputChange}
                pattern='[a-zA-Z\s]+'
                required
              />
              <label htmlFor="paterno">Paterno</label>
            </div>
            <div className="input-field col s3">
              <input
                id="materno"
                type="text"
                name="materno"
                className="validate"
                value={formData.materno}
                onChange={handleInputChange}
                pattern='[a-zA-Z\s]+'
              />
              <label htmlFor="materno">Materno</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s4">
              <input
                id="curp"
                type="text"
                name="curp"
                className={`validate ${errors.curp || errors.curpExists ? 'invalid' : ''}`}
                value={formData.curp}
                onChange={handleInputChange}
                required
                minLength="18"
                maxLength="18"
                pattern="[a-zA-Z0-9]{18}"
                data-error={errors.curp || errors.curpExists}
                data-success="CURP válido"
              />
              <label htmlFor="curp">CURP</label>
              <span className="helper-text red-text" data-error={errors.curp || errors.curpExists} style={{color: 'red'}}>{errors.curp || errors.curpExists}</span>
            </div>
            <div className="input-field col s4">
              <input
                id="puesto"
                type="text"
                name="puesto"
                className="validate"
                required
                value={formData.puesto}
                onChange={handleInputChange}
              />
              <label htmlFor="puesto">Puesto</label>
            </div>
            <div className="input-field col s4">
              <input
                id="site"
                type="text"
                name="site"
                className="validate"
                required
                value={formData.site}
                onChange={handleInputChange}
              />
              <label htmlFor="site">SITE</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s3">
              <input
                id="he"
                type="text"
                name="he"
                className="validate timepicker"
                value={formData.he}
                onChange={handleInputChange}
                required
                ref={timePickerRefHe}
              />
              <label htmlFor="he">Horario Entrada</label>
            </div>
            <div className="input-field col s3">
              <input
                id="hs"
                type="text"
                name="hs"
                className="validate timepicker"
                value={formData.hs}
                onChange={handleInputChange}
                required
                ref={timePickerRefHs}
              />
              <label htmlFor="hs">Horario Salida</label>
            </div>
            <div className="input-field col s3">
              <input
                id="fechaDeInicio"
                type="text"
                name="fechaDeInicio"
                className="validate datepicker"
                value={formData.fechaDeInicio}
                onChange={handleInputChange}
                ref={datePickerRefFechaDeInicio}
              />
              <label htmlFor="fecha_entrada">Fecha de Inicio</label>
            </div>
            <div className="input-field col s4">
              <input
                id="email"
                type="email"
                name="email"
                className="validate"
                value={formData.email}
                onChange={handleInputChange}
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <StyledFormButton type="submit" disabled={loading}>
                {loading ? 'Loading...' : 'Nuevo Empleado'}
              </StyledFormButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AltaEmpleado;