import React, { useEffect, useState, useCallback, useRef } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { DateTime } from "luxon";
import { useGetClientId } from "../../hooks/useGetUserClientId";
import M from 'materialize-css/dist/js/materialize.min.js';

const CountFTableWeek = () => {
  const CLIENTID = useGetClientId();
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);

  // Calculate dates for the last seven days
  const fecha_fin = DateTime.now().toFormat("yyyy-MM-dd");
  const fecha_inicio = DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd");

  const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/${fecha_inicio}/${fecha_fin}/${CLIENTID}`;

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(URL_GET);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      // Process data to count "F" in horas for each employee
      const processedData = data.reduce((acc, item) => {
        const existingEmployee = acc.find(emp => emp.employee_number === item.employee_number);
        if (existingEmployee) {
          existingEmployee.f_count += item.hora === "F" ? 1 : 0;
        } else {
          acc.push({
            employee_number: item.employee_number,
            nombre: item.nombre,
            f_count: item.hora === "F" ? 1 : 0,
          });
        }
        return acc;
      }, []);

      return processedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }, [URL_GET]);

  const initializeTable = useCallback((data) => {
    if (tableRef.current) {
      new Tabulator(tableRef.current, {
        data,
        layout: "fitColumns",
        pagination: "local",
        paginationSize: 20,
        initialSort: [
          { column: "f_count", dir: "desc" }, // Sort by "Count of 'F'" descending by default
        ],
        columns: [
          { title: "#", field: "rownum", formatter: "rownum", width: 30 },
          { title: "Emp_Id", field: "employee_number", formatter: "plaintext", width: 60 },
          { title: "Nombre", field: "nombre", formatter: "plaintext", width: 150 },
          { title: "Faltas", field: "f_count", formatter: "plaintext", hozAlign: "center", width: 60 },
        ],
      });
    }
  }, []);

  useEffect(() => {
    const loadTable = async () => {
      setLoading(true);
      try {
        const data = await fetchData(); // Fetch data based on the selected date range
        if (tableRef.current) {
          initializeTable(data);
        }
      } catch (error) {
        console.error("Error loading table:", error);
        M.toast({ html: `Error loading table: ${error.message}`, classes: "custom-toast" });
      } finally {
        setLoading(false);
      }
    };

    loadTable();
  }, [fetchData, initializeTable]);

  return (
    <>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      <div ref={tableRef} id="f-count-table"></div>
    </>
  );
};

export default CountFTableWeek;