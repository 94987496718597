
import Login from './components/Login';

import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import Dashboard from './pages/DashboardPage'
import CalendarioPage from './pages/CalendarioPage';
import EmployeesPage from './pages/EmployeesPage';
import IncidenciasPage from './pages/IncidenciasPage';
import FormatPage from "./pages/FormatsPage";
import { Auth } from './pages/Auth';



function App() {
  return (

    <div>

    <Router>
      <Routes >
      <Route path='/login' element={<Login />}></Route>
      
      <Route path='/' element={<HomePage/>}></Route>
      <Route path='/auth' element={<Auth/>}></Route>
      <Route path='/clientid'> CLIENTID</Route>

      <Route path='/dashboard' element={<Dashboard/>}></Route>
      <Route path='/emps' element={<EmployeesPage/>}></Route>
      <Route path='/cale' element={<CalendarioPage/>}></Route>
      <Route path='/inci' element={<IncidenciasPage/>}></Route>
      <Route path='/pref' element={<FormatPage/>}></Route>
      </Routes>

    </Router>

    </div>

   
     
  );
}

export default App;
