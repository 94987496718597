import styled from 'styled-components';
import background from './../assets/20220324_013807.jpg'
import { Link } from 'react-router-dom';



export const colors = {
   primary: "#fff",
   theme: "orangered",
   light1: "#F3F4F6",
   light2: "#E5E7EB",
   dark1: "#1F2937",
   dark2: "#4B5563",
   dark3: "#9CA3AF",
   red:  "#DC2626",
   excel: "#217346"


}


export const StyledContainer = styled.div`
    margin: 0;
    min-height: 100vh;
    display: block;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${background});
    background-size: cover;
    background-attachment: fixed;
`;

export const StyledTitle = styled.h2`
    font-size: ${(props) => props.size}px;
    font-family: 'Roboto'
    display: center;
    text-align: center;
    color: ${(props) => props.color ? props.color : colors.primary};
    padding: 5px;
    margin-bottom: 10px;
`;

export const StyledSubtitle = styled.p`
    font-size: ${(props) => props.size}px;
    text-align: center;
    color: ${(props) => props.color ? props.color : colors.primary};
    padding: 20px;
    
`;

export const Avatar = styled.div`
    margin: 0;
    height: 85px;
    width: 85px;
    border-radius: 50px;
    background: url(${props => props.$image});
    background-size: cover;
    background-position: center;
    margin: auto

`;

export const StyledButton = styled(Link)`
    padding: 10px;
    width: 150px;
    background-color: transparent;
    font-size: 18px;
    border: solid;
    border-radius: 25px;
    color: ${colors.primary};
    text-decoration: none;
    text-align:center;
    transition: ease-in-out 0.3s;
    outline: 0;

    &:hover{
    background-color: ${colors.primary};
    color: ${colors.theme};
    cursor: pointer;
    
`;

export const ButtonGroup = styled.div`
display: flex;
justify-content: space-around;
flex-direction: row;
align-items: center;
margin-top: 15px;

`;


export const StyledtextInput = styled.input`
width: 250px;
padding: 15px;
padding-left: 50px;
font-size: 17px;
letter-spacing: 1px;
color: ${colors.dark1};
background-color: ${colors.light2}
border: 0;
outline: 0;
display:block;
margin: 5px auto 10px auto;
transition: ease-in-out 0.3s;


${(props) => props.invalid &&
     `background-color: ${colors.red}; color: ${colors.primary};`}

&:focus{
background-color: ${colors.dark2};
color: ${colors.primary};
}
`;

export const StyledLabel = styled.p`
 text-align: left;
 font-size: 13px;
 font-weight: bold;

`;



export const StyledFormArea = styled.div`
background-color: ${props => props.bg || colors.light1};
text-align : center;
width: 500px;
max-width: 500px; /* Ensures the width doesn't exceed 500px */
min-width: 500px; /* Ensures the width doesn't shrink below 500px */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the div horizontally and vertically */
padding: 45px 55px;
`;

export const StyledFormButton = styled.button`
padding: 8px;
width: 120px;
background-color: transparent;
font-size: 16px;
border: 2px solid ${colors.theme};
border-radius: 25px;
color: ${colors.theme};
transition: ease-in-out 0.3s;
outline: 0;

&:hover{
background-color: ${colors.theme};
color: ${colors.primary};
cursor: pointer;
}
`;

export const StyledIcon = styled.p`
color: ${colors.dark1};
position: absolute;
font-size: 21px;
top: 35px;
${(props) => props.right && `right: 15px; `};
${(props) => !props.right && `left: 15px; `}

`;


export const ErrorMsg = styled.div`
font-size: 13px;
color: ${colors.red};
margin-top: -5px;
margin-bottom: 10px;
text-align: left;

`;

export const ExtraText = styled.p`
font-size: ${(props) => props.size}px;
text-align: center;
color: ${(props) => (props.color ? props.color : colors.dark2) };
padding: 2px;
margin-top: 10px;
`;

export const NavbarButtons = styled(Link)`
    padding: 10px;
    width: 150px;
    background-color: transparent;
    font-size: 16px;
   
    border-radius: 5px;
    color: ${colors.theme};
    text-decoration: none;
    text-align:center;
    transition: ease-in-out 0.3s;
    outline: 0;

    &:hover{
    background-color: ${colors.theme};
    color: ${colors.primary};
    cursor: pointer;
    
`;

export const LogOutButtons = styled.button`
    padding: 10px;
    width: 110px;
    background-color: transparent;
    font-size: 16px;
    border: none;
   
    border-radius: 5px;
    color: ${colors.theme};
    text-decoration: none;
    text-align:center;
    transition: ease-in-out 0.3s;
    outline: 0;

    &:hover{
    background-color: ${colors.theme};
    color: ${colors.primary};
    cursor: pointer;
    
`;


export const StyledExcelButton = styled.button`
padding: 8px;
width: 130px;
background-color: transparent;
font-size: 14px;
border: 2px solid ${colors.excel};
border-radius: 25px;
color: ${colors.excel};
transition: ease-in-out 0.3s;
outline: 0;

&:hover{
background-color: ${colors.excel};
color: ${colors.primary};
cursor: pointer;
}
`;