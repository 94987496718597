import React from 'react';
import {
  StyledSubtitle,
  Avatar,
  colors
} from '../components/Styles';
import Logo from "../assets/liccoLogo.png";
import Navbar from '../components/NavbarUsers';
import CountFTableWeek from '../components/dashboard/TablaFaltasWeek';
import CountTablaHoy from '../components/dashboard/countHoy';
import Footer from '../components/Footer';

const Dashboard = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <Avatar $image={Logo} />

      <div className='row'>
        <div className='col s3'>
          <StyledSubtitle color={colors.theme}>
            Faltas en los últimos 7 dias
          </StyledSubtitle>
          <CountFTableWeek />
        </div>
        <div className='col s6'>
          <StyledSubtitle color={colors.theme}>
            Movimientos el día de hoy
          </StyledSubtitle>
          <CountTablaHoy />
        </div>
        <div className='col s3'>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Dashboard;