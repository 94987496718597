import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Suppress ResizeObserver loop error
const resizeObserverLoopErr = () => {
  const resizeObserverErrDiv = document.createElement('div');
  resizeObserverErrDiv.id = 'resizeObserverErrDiv';
  resizeObserverErrDiv.style.display = 'none';
  document.body.appendChild(resizeObserverErrDiv);

  const resizeObserverErr = () => {
    const errDiv = document.getElementById('resizeObserverErrDiv');
    if (errDiv) {
      errDiv.innerHTML += 'ResizeObserver loop error detected.<br>';
    }
  };

  const observerErrorHandler = (event) => {
    if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
      event.stopImmediatePropagation();
      resizeObserverErr();
    }
  };

  window.addEventListener('error', observerErrorHandler);

  window.addEventListener('unhandledrejection', (event) => {
    if (event.reason && event.reason.message === 'ResizeObserver loop completed with undelivered notifications.') {
      event.stopImmediatePropagation();
      resizeObserverErr();
    }
  });
};

resizeObserverLoopErr();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);