import React, { useEffect, useState, useRef, useCallback } from "react";
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator.min.css";
import "materialize-css/dist/css/materialize.min.css";
import { isTrue } from "../apiCredentials";
import { useGetClientId } from "../../hooks/useGetUserClientId";
import "materialize-css/dist/js/materialize.min.js";
import M from 'materialize-css/dist/js/materialize.min.js';
import * as XLSX from 'xlsx';  // Import xlsx library
import { DateTime } from 'luxon';
import { StyledExcelButton } from "../Styles";

window.DateTime = DateTime;

const EmployeeCatalogComponent = () => {
  const CLIENTID = useGetClientId();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const tableRef = useRef(null);
  const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${CLIENTID}`;
  const URL_POST = "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleado/actualizar";

  const loadTable = useCallback(async () => {
    try {
      const response = await fetch(URL_GET);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const jsdata = await response.json();

      if (tableRef.current) {
        const newTable = new Tabulator(tableRef.current, {
          data: jsdata,
          maxWidth: "100%", 
          layout: "fitData",
          renderHorizontal: "virtual",
          resizableColumnFit: true,
          pagination: "local", // Enable local pagination
          paginationSize: 25, // Number of rows per page
          columns: [
            { title: "#", field: "rownum", formatter: "rownum", width: 30, frozen: true },
            {
              title: "Emp ID",
              field: "empnum",
              frozen: true,
              headerFilter: "input",
              headerFilterParams: {
                placeholder: "Search...",
                minSearchChars: 2,
              },
              headerFilterPlaceholder: "Buscar...",
            },
            {
              title: "Nombre",
              field: "nombre",
              editor: "input",
              validator: "required",
              headerFilter: "input",
              headerFilterParams: {
                minSearchChars: 2,
              },
              headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
            },
            {
              title: "Paterno",
              field: "paterno",
              editor: "input",
              validator: "required",
              headerFilter: "input",
              headerFilterParams: {
                minSearchChars: 2,
              },
              headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
            },
            {
              title: "Materno",
              field: "materno",
              editor: "input",
              headerFilter: "input",
              headerFilterParams: {
                minSearchChars: 2,
              },
              headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
            },
            {
              title: "CURP",
              field: "curp",
              editor: "input",
              validator: ["minLength:18", "maxLength:18", "string"],
              formatter: "plaintext",
              headerFilter: "input",
              headerFilterParams: {
                minSearchChars: 2,
              },
              headerFilterFunc: (headerValue, rowValue) => rowValue.toLowerCase().includes(headerValue.toLowerCase()),
            },
            { title: "Contrato", field: "contract_code", editor: "input", validator: "required" },
            { title: "Puesto", field: "e_attribute3", editor: "input" },
            {
              title: "Ingreso",
              field: "fechadeinicio",
              editor: "date",
              validator: "required",
              editorParams: {
                format: "yyyy-MM-dd",
                verticalNavigation: "table",
              },
            },
            {
              title: "Entrada",
              field: "horaentrada",
              validator: "required",  
              editor: "time",
              editorParams: { format: "HH:mm" },
              formatter: (cell) => DateTime.fromFormat(cell.getValue(), "HH:mm").toFormat("HH:mm"),
            },
            {
              title: "Salida",
              field: "horasalida",
              validator: "required",  
              editor: "time",
              editorParams: { format: "HH:mm" },
              formatter: (cell) => DateTime.fromFormat(cell.getValue(), "HH:mm").toFormat("HH:mm"),
            },
            { title: "Site", field: "e_attribute4", editor: "list",
              editorParams: { values: { XOLA: "XOLA", AÑIL: "AÑIL", TRONCOSO: "TRONCOSO", "HOME OFFICE": "HOME OFFICE" } },
              headerFilter: true,
              headerFilterParams: { values: { XOLA: "XOLA", AÑIL: "AÑIL", TRONCOSO: "TRONCOSO", "HOME OFFICE": "HOME OFFICE" }, clearable: true } },
            {
              title: "STATUS",
              field: "activoinactivo",
              validator: "required",
              editor: "list",
              editorParams: { values: { Activo: "Activo", Inactivo: "Inactivo" } },
              headerFilter: true,
              headerFilterParams: { values: { Activo: "Activo", Inactivo: "Inactivo", "": "Todos" }, clearable: true },
            },
            { title: "Fecha Baja", field: "fechabaja", editor: "input" },
            { title: "Frecuencia", field: "frecuenciadepago", editor: "input" },
            {
              title: "Acceso",
              field: "fijamovil",
              editor: "list",
              editorParams: { values: { Fija: "Fija", Movil: "Movil" } },
              headerFilter: true,
              headerFilterParams: { values: { Fija: "Fija", Movil: "Movil", "": "Todos" }, clearable: true },
            },
           // { title: "Foto", field: "fotoenviada", editor: "input" },
            //{ title: "Email", field: "email", editor: "input" },
          ],
        });

        newTable.on("cellEdited", async function (cell) {
          if (!isTrue) {
            M.toast({ html: "You do not have permission to edit this field.", classes: "red" });
            return;
          }
          const updatedRow = cell.getRow().getData();
          const fieldName = cell.getField();
          const updatedValue = cell.getValue();

          const payload = {
            empnum: updatedRow.empnum,
            nombre: updatedRow.nombre.toUpperCase(),
            paterno: updatedRow.paterno.toUpperCase(),
            materno: updatedRow.materno ? updatedRow.materno.toUpperCase() : " ",
            curp: updatedRow.curp ? updatedRow.curp.toUpperCase() : " ",
            sucursal: updatedRow.sucursal,
            frecuenciaDePago: updatedRow.frecuenciadepago.toUpperCase(),
            fechaDeInicio: updatedRow.fechadeinicio,
            fechaBaja: updatedRow.fechabaja ? updatedRow.fechabaja : "",
            fechaReingreso: "",
            fijamovil: updatedRow.fijamovil ? updatedRow.fijamovil : " ",
            horarioDeEntrada24hh: updatedRow.horaentrada ? updatedRow.horaentrada : " ",
            horarioDeSalida60mm: updatedRow.horasalida ? updatedRow.horasalida : " ",
            toleranciaARetardoMmssNo: updatedRow.e_attribute1 ? updatedRow.e_attribute1.toUpperCase() : " ",
            horasExtraSino: updatedRow.e_attribute2 ? updatedRow.e_attribute2.toUpperCase() : " ",
            turnoDominicalSino: updatedRow.e_attribute3 ? updatedRow.e_attribute3.toUpperCase() : " ",
            diasDeDescanso16: updatedRow.e_attribute4 ? updatedRow.e_attribute4.toUpperCase() : " ",
            horasSemanales: updatedRow.e_attribute5 ? updatedRow.e_attribute5.toUpperCase() : " ",
            activoInactivo: updatedRow.activoinactivo,
            fotoEnviada: updatedRow.fotoenviada ? updatedRow.fotoenviada : " ",
            email: updatedRow.email ? updatedRow.email : " ",
            clientid: CLIENTID,
          };

          try {
            const options = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              mode: "no-cors",
              body: JSON.stringify(payload),
            };

            await fetch(URL_POST, options);
            console.log("Data sent successfully");
            M.toast({
              html: `Nuevo Valor: ${updatedValue}\nActualizado Correctamente!`,
              classes: "green",
            });
          } catch (error) {
            console.error("Error saving data:", error);
            M.toast({
              html: `Failed to update field: ${fieldName}\nError: ${error.message}`,
              classes: "red",
            });
          }
        });

        setTable(newTable);
      }
    } catch (error) {
      console.error("Error loading table data:", error);
    } finally {
      setLoading(false);
    }
  }, [CLIENTID, URL_GET, URL_POST]);

  useEffect(() => {
    if (tableRef.current) {
      loadTable();
    }
  }, [loadTable]);

  const downloadExcel = () => {
    if (!table) {
      console.error("Table instance not found");
      return;
    }
    const data = table.getData(); // Get the data directly
  
    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "catalogo_Empleados.xlsx");
  };

  return (
    <div style={{ padding: "10px" }}>
      <div ref={tableRef} id="employee-table"></div>
      <StyledExcelButton onClick={downloadExcel}>
        Descargar Excel
      </StyledExcelButton>
      {loading && <div className="progress"><div className="indeterminate"></div></div>}
    </div>
  );
};

export default EmployeeCatalogComponent;