import AltaEmpleado from "../components/empleados/AltaEmpleado"
import EmployeeCatalogComponent from "../components/empleados/Employees"
import Footer from "../components/Footer"
import Navbar from "../components/NavbarUsers"
import { StyledSubtitle, colors } from "../components/Styles"




const EmployeesPage = () => {
    return (
        <div>
        
        <Navbar />
        <StyledSubtitle color={colors.theme} size={24}>
            Catalogo de Empleados
        </StyledSubtitle>
        <EmployeeCatalogComponent />
        <StyledSubtitle color={colors.theme} size={24}>
            Alta Nuevo Empleado
        </StyledSubtitle>
        <AltaEmpleado />
        <Footer/>
        </div>
    )
}

export default EmployeesPage