import React, { useEffect, useState, useRef } from "react";
import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { useGetClientId } from "../../hooks/useGetUserClientId";

// Register necessary Chart.js components
Chart.register(ArcElement, Tooltip, Legend, DoughnutController);

const CountTablaHoy = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const tableRef = useRef(null);
  const chartCanvasRef = useRef(null);
  const chartRef = useRef(null);
  const CLIENTID = useGetClientId();

  const getTodayBounds = () => {
    const date = new Date();
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return { 
      startOfMonth: formatDate(startOfDay), 
      endOfMonth: formatDate(endOfDay) 
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { startOfMonth, endOfMonth } = getTodayBounds();
        const URL = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/${startOfMonth}/${endOfMonth}/${CLIENTID}`;

        const response = await fetch(URL);
        const result = await response.json();

        // Count occurrences of specific values in the 'horas' field
        const counts = {
          F: 0,
          R: 0,
          V: 0,
          INC: 0,
          Numeric: 0, // For numeric values in the 'hora' field
          HO: 0,
          PH: 0,
        };

        result.forEach(item => {
          if (item.hora === 'F') counts.F++;
          if (item.retardo === 'Y') counts.R++;
          if (item.hora === 'V') counts.V++;
          if (item.hora === 'INC') counts.INC++;
          if (/\d+/.test(item.hora)) counts.Numeric++;
          if (item.hora === 'HO') counts.HO++;
          if (item.hora === 'PH') counts.PH++;
        });

        // Prepare data for Tabulator
        const tabulatorData = [
          { tipo: 'Pases de Asistencia', count: counts.Numeric }, 
          { tipo: 'Faltas', count: counts.F},
          { tipo: 'Retardos', count: counts.R},
          { tipo: 'Vacaciones', count: counts.V},
          { tipo: 'Incapacidades', count: counts.INC},
          { tipo: 'Home Office', count: counts.HO},
          { tipo: 'Pago Horas', count: counts.PH}
        ];

        setData(tabulatorData);

        // Prepare data for Pie Chart
        setChartData({
          labels: ['Faltas', 'Retardos', 'Vacaciones', 'Incapacidades', 'Pases de Asistencia', 'Home Office', 'Pago Horas'],
          datasets: [
            {
              label: 'Cantidad',
              data: [counts.F, counts.R, counts.V, counts.INC, counts.Numeric, counts.HO, counts.PH],
              backgroundColor: [
                '#DC3545', // Color for Faltas
                '#FF4500', // Color for Retardos
                '#36A2EB', // Color for Vacaciones
                '#FFCE56', // Color for Incapacidades
                '#22BB33', // Color for Numeric Values (Pases de Asistencia)
                '#A020F0', // Color for Home Office
                '#46BDC6', // Color for Pago Horas
              ],
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 1,
            },
          ],
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [CLIENTID]);

  useEffect(() => {
    if (!loading && tableRef.current && data.length > 0) {
      // Initialize Tabulator
      new Tabulator(tableRef.current, {
        data: data,
        layout: 'fitColumns',
        columns: [
          { title: 'Registro', field: 'tipo' },
          { title: 'Incidencias', field: 'count', hozAlign: 'center', width: 75 },
        ],
      });
    }
  }, [loading, data]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    if (chartCanvasRef.current) {
      const ctx = chartCanvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, {
        type: 'doughnut',
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                    label += context.parsed;
                  }
                  return label;
                }
              }
            }
          }
        }
      });
    }
  }, [chartData]);

  return (
    <div>
      {loading ? (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      ) : (
        <div>
          <div ref={tableRef}></div>
          <div className='container'>
            <canvas ref={chartCanvasRef}></canvas>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountTablaHoy;